<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Lokasyon"
          label-for="id_com_location"
        >
          <v-select
            id="id_com_location"
            v-model="filterData.id_com_location"
            placeholder="Lokasyon"
            :options="locations"
            label="title"
            :reduce="item => item.id"
            @input="filterAction"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :reduce="brand => brand.id"
            @input="filterAction"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Durum"
          label-for="id_com_sparepart_order_status"
        >
          <v-select
            id="id_com_sparepart_order_status"
            v-model="filterData.id_com_sparepart_order_status"
            placeholder="Durum"
            :options="orderStatuses2"
            label="title"
            :reduce="item => item.id"
            @input="filterAction"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'PublicFilter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    actionFilter: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    resetFilter: {
      type: Function,
    },
  },
  data() {
    return {
      filterData: {
        id_com_location: null,
        id_com_brand: null,
        id_com_sparepart_order_status: null,
      },
    }
  },
  computed: {
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    orderStatuses() {
      return this.$store.getters['sparepartOrderStatus/dataList']
    },
    orderStatuses2() {
      const data = [{ id: 0, title: 'Beklemede' }]
      data.push(...this.orderStatuses)
      return data
    },
  },
  created() {
    this.getStatuses()
    this.getLocations()
    this.getBrands()
  },
  methods: {
    getStatuses() {
      this.$store.dispatch('sparepartOrderStatus/getDataList', {
        select: ['com_sparepart_order_status.id AS id', 'com_sparepart_order_status.title AS title'],
      })
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    filterAction() {
      this.actionFilter(this.filterData)
    },
    resetAction() {
      this.filterData = {
        id_com_location: null,
        id_com_brand: null,
      }
      this.resetFilter()
    },
  },
}
</script>
<style scoped></style>
<style lang="scss">

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
