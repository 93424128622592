<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-filter="filterData"
          :reset-filter="resetSearch"
        />
      </b-card>
    </b-col>
    <b-col md="12">
      <div
        v-if="loading"
        class="text-primary"
      >
        <loading />
      </div>
      <b-card no-body>
        <vue-perfect-scrollbar>
          <b-table
            class="mt-1 font-small-3"
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            hover
            small
          >
            <template #head(interview_number)>
              <FeatherIcon icon="EyeIcon" />
            </template>
            <template #cell(interview_number)="data">
              <div>
                <b-button
                  variant="flat-primary"
                  size="sm"
                  :to="'spareparts/view/' + data.item.interview_number"
                  class="btn-icon"
                  pill
                >
                  <FeatherIcon icon="EyeIcon" />
                </b-button>
              </div>
            </template>
            <template #cell(spare_code)="data">
              <div class="font-small-2">
                {{ data.item.spare_code }}
              </div>
              <div class="text-warning font-small-2">
                Sipariş Tipi: {{ data.item.sparepart_order_type }}
              </div>
              <div class="text-success font-small-2">
                Adet: {{ data.item.piece }}
              </div>
            </template>
            <template #cell(customer)="data">
              <div>
                {{ data.item.customer }}
              </div>
              <div
                v-if="data.item.company_name"
                class="font-small-2 text-warning"
              >
                {{ data.item.company_name }}
              </div>
              <div
                class="font-small-2 text-warning"
              >
                {{ data.item.brand }} - {{ data.item.location }}
              </div>
              <div
                class="font-small-2 text-primary"
              >
                {{ data.item.license_plate }}
              </div>
            </template>
            <template #cell(username)="data">
              <div class="text-nowrap">
                {{ data.item.username }}
              </div>
              <div class="font-small-2 text-warning">
                {{ moment(data.item.adddate).format('ll') }}
              </div>
            </template>
            <template #cell(odate)="data">
              <span v-if="data.item.odate">{{ moment(data.item.odate).format('ll') }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(adate)="data">
              <span v-if="data.item.adate">
                {{ moment(data.item.adate).format('ll') }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(ddate)="data">
              <span v-if="data.item.ddate">
                {{ moment(data.item.ddate).format('ll') }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(price)="data">
              {{ data.item.price | toCurrency }} ₺
            </template>
            <template #cell(waitingdays)="data">
              <div v-if="!data.item.ddate">
                <div
                  v-if="data.item.adddate"
                  class="font-small-2 text-danger"
                >
                  Talep: {{ moment().diff(moment(data.item.adddate),'days') }} Gün
                </div>
                <div
                  v-if="data.item.odate"
                  class="text-info font-small-2"
                >
                  Sipariş: {{ moment().diff(moment(data.item.odate),'days') }} gün
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <div v-if="data.item.status">
                {{ data.item.status }}
                <div class="text-danger font-small-2">
                  {{ data.item.last_user }}
                </div>
              </div>
              <div
                v-else
                class="font-weight-bold text-danger"
              >
                Beklemede
              </div>
            </template>
          </b-table>
        </vue-perfect-scrollbar>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Loading from '@/layouts/components/Loading.vue'
import PublicFilter from '@/views/Spareparts/Index/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    PublicFilter,
    Loading,
    VuePerfectScrollbar,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'interview_number',
          label: 'No',
          thClass: 'font-small-2 align-middle text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'spare_code',
          label: 'Parça Kodu',
          thClass: 'font-small-2 align-middle',
        },
        {
          key: 'customer',
          label: 'Müşteri',
          thClass: 'font-small-2 align-middle',
        },
        {
          key: 'username',
          label: 'Siparişi Veren',
          thClass: 'font-small-2 align-middle',
        },
        {
          key: 'odate',
          label: 'Sipariş Tarihi',
          thClass: 'font-small-2 align-middle',
          tdClass: 'text-nowrap',
        },
        {
          key: 'adate',
          label: 'Geliş Tarihi',
          thClass: 'font-small-2 align-middle',
        },
        {
          key: 'ddate',
          label: 'Teslim Tarihi',
          thClass: 'font-small-2 align-middle',
        },
        {
          key: 'price',
          label: 'Birim Maliyet',
          thClass: 'font-small-2 align-middle',
        },
        {
          key: 'waitingdays',
          label: 'Bekleyen Gün Sayısı',
          thClass: 'font-small-2 align-middle',
        },
        {
          key: 'status',
          label: 'Durum',
          thClass: 'font-small-2 align-middle',
        },
      ],
      dataQuery: {
        select: [
          'com_sparepart_order.id AS id',
          'com_sparepart_order.interview_number AS interview_number',
          'com_sparepart_order_type.title AS sparepart_order_type',
          'com_sparepart_order.spare_code AS spare_code',
          'com_sparepart_order.piece AS piece',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_user.name AS username',
          'com_brand.name AS brand',
          'com_location.title AS location',
          'com_sparepart_order.adddate AS adddate',
          'com_sparepart_order.odate AS odate',
          'com_sparepart_order.adate AS adate',
          'com_sparepart_order.price AS price',
          'com_sparepart_order.ddate AS ddate',
          'com_sparepart_order.adate AS adate',
          'com_sparepart_order_status.title AS status',
          'users.name AS last_user',
          'com_cars.license_plate AS license_plate',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    loading() {
      return this.$store.getters['sparePartOrders/dataLoading']
    },
    dataList() {
      return this.$store.getters['sparePartOrders/dataList']
    },
    dataCount() {
      return this.$store.getters['sparePartOrders/dataCounts']
    },
    saveStatus() {
      return this.$store.getters['sparePartOrders/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    resetSearch() {
      this.dataQuery.where = {}
      this.getDataList()
    },
    filterData(params) {
      const where = {}
      if (params.id_com_brand) {
        where['com_sparepart_order.id_com_brand'] = params.id_com_brand
      }
      if (params.id_com_location) {
        where['com_sparepart_order.id_com_location'] = params.id_com_location
      }
      if (params.id_com_sparepart_order_status) {
        where['com_sparepart_order.id_com_sparepart_order_status'] = params.id_com_sparepart_order_status
      } else if (params.id_com_sparepart_order_status === 0) {
        where['com_sparepart_order.id_com_sparepart_order_status'] = null
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('sparePartOrders/getDataList', this.dataQuery)
    },
  },
}
</script>
